/* global functions */
import PerfectScrollbar from './../perfect-scrollbar.min';
import Handlebars from './../handlebars-v1.3.0.min'

// TODO for now all functions here have been made globally available (same behavior as with assetic)
// If this is to be cleaner, it needs to be changed.

window.categories = {};
// get categories from DOM for later use in this file
document.addEventListener('DOMContentLoaded', () => {
    $('#categories-well-filter').children('label').each(function () {
        var parent = $(this).children('input').val();
        window.categories[parent] = "";

        var selector = '#child_cat_' + parent;
        $(selector).children('label').each(function () {
            var child = $(this).children('input').val();
            window.categories[child] = parent;
        });

        selector = '#child_categories_' + parent;
        $(selector).children('label').each(function () {
            var child = $(this).children('input').val();
            window.categories[child] = parent;
        });
    });
});

window.ctrl_active = false;
$(window).keydown(function (e) {
    if (e.which == 17 || e.which == 91)
        window.ctrl_active = true;
}).keyup(function (e) {
    if (e.which == 17 || e.which == 91)
        window.ctrl_active = false;
});

$(document).ready(function () {
    ref_popover($("[data-toggle=popover]"));

    var last_form;

    (function () {
        // default settings
        $.ajaxSetup({
            timeout: 300000,
            cache: false,
            context: function () {
                return this;
            }
        });
        // cache last ajax req
        var ajax_req;
        // not logged in
        $(document).ajaxError(function (event, req, settings) {
            var json = req.responseJSON || null;

            if (req.status === 403) {
                // login tried but wrong credentials
                if (json && json.error) {
                    $('#loginModal .error, #signin2-div .error').html(json.error).slideDown().parents('.error-wrapper').slideDown();
                    // login needed
                } else {
                    // cache event
                    ajax_req = {
                        event: event,
                        req: req,
                        settings: settings
                    };
                }
            } else if (req.status == 500) {
                var json = req.responseJSON;
                if (!json || (!json.question && !json.modal && !json.field)) {
                    if (!ajax_err(req.responseJSON)) {
                        show_err("An unexpected error occured. Please contact support.");
                        //console.log(req);
                    }
                }
            }
        })
            // default catcher
            .ajaxComplete(function (event, req, settings) {
                var json = req.responseJSON || null;
                if (!json || !json.redirect) {
                    $('html').removeClass('async-loading');
                    $('[data-loading-reset]').button('reset').removeAttr('data-loading-reset');
                }

                if (json) {
                    // new stuff notifications
                    if (json.newNotification) {
                        (function () {
                            var close_notification = function (notification) {
                                var url = $('html').attr('data-notifications-url');
                                url += '/' + notification.attr('data-id') + '/read';
                                $.get(url);

                                if (notification.hasClass('popover')) {
                                    notification.popover('destroy');
                                } else {
                                    notification.slideUp(function () {
                                        $(this).remove();
                                    });
                                    $('body').removeClass('has-no-field-notification');
                                }
                            };
                            // close notification
                            $('body').on('click', '.new-notification .close', function (e) {
                                e.stopImmediatePropagation();
                                e.stopPropagation();
                                e.preventDefault();

                                close_notification($(this).closest('.new-notification'))
                            });
                            // click on notification
                            $('body').on('click', '.new-notification', function (e) {
                                e.stopImmediatePropagation();
                                e.stopPropagation();
                                e.preventDefault();

                                var $this = $(this);
                                var redirect_url = $this.attr('data-url');
                                if (redirect_url) {
                                    window.open(redirect_url);
                                }

                                close_notification($this);
                                return false;
                            });

                            // if (json.newNotification.field) {
                            if (json.newNotification.field && !window.location.href.match(/[\?&]notification-type=top/)) {
                                var notify_field = $(json.newNotification.field);
                                // popover on a field
                                if (notify_field.length) {
                                    var notify_field_tmplt = $('<div class="popover new-notification no-click-close" role="tooltip"><div class="arrow"></div><button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button><h3 class="popover-title"></h3><div class="popover-content"></div></div>');
                                    if (json.newNotification.id) {
                                        notify_field_tmplt.attr('data-id', json.newNotification.id);
                                    }
                                    if (json.newNotification.url) {
                                        notify_field_tmplt.attr('data-url', json.newNotification.url);
                                    }

                                    notify_field.data('template', notify_field_tmplt.get(0).outerHTML);
                                    if (json.newNotification.title) {
                                        notify_field.data('title', json.newNotification.title);
                                    }
                                    if (json.newNotification.text) {
                                        notify_field.data('content', json.newNotification.text);
                                    }
                                    notify_field.popover({
                                        trigger: 'manual',
                                        placement: 'auto',
                                        container: 'body'
                                    }).popover('show');

                                    $(window).on('resize', function () {
                                        notify_field.popover('show');
                                    });
                                }
                            } else
                                // top notification
                                if (json.newNotification.text || json.newNotification.title) {
                                    var notify_div = $('<div class="no-field new-notification"><button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button><strong></strong><text></text></div>');
                                    if (json.newNotification.id) {
                                        notify_div.attr('data-id', json.newNotification.id);
                                    }
                                    if (json.newNotification.url) {
                                        notify_div.attr('data-url', json.newNotification.url);
                                    }
                                    notify_div.find('strong').html(json.newNotification.title);
                                    notify_div.find('text').html(json.newNotification.text);
                                    //notify_div.hide();

                                    $('body').prepend(notify_div);
                                    $('body').addClass('has-no-field-notification');
                                    //notify_div.slideDown();
                                }
                        })();
                    }
                    // standard notifications
                    if (json.notification) {
                        notification(json.notification, json.hide_other_notifications ? 1 : 0);
                    }
                    if (json.notifications) {
                        for (var n in json.notifications) {
                            (function () {
                                var n1 = json.notifications[n];
                                setTimeout(function () {
                                    notification(n1, json.hide_other_notifications ? 1 : 0);
                                }, 300);
                            })();
                        }
                    }
                    if (json.redirect) {
                        if (json.notification || json.notifications) {
                            setTimeout(function () {
                                if (json.redirect == 'back') {
                                    window.history.back(-1);
                                } else if (json.redirect == 'reload') {
                                    document.location.reload();
                                } else {
                                    document.location.href = json.redirect;
                                }
                            }, json.timeout || 1500)
                            return;
                        }
                        if (json.redirect == 'back') {
                            window.history.back(-1);
                        } else if (json.redirect == 'reload') {
                            document.location.reload();
                        } else {
                            document.location.href = json.redirect;
                        }
                        return;
                    }
                    if (json.signin && json.signin == 1) {
                        // repeat cached event
                        if (ajax_req && ajax_req.settings) {
                            $.ajax(ajax_req.settings);
                            ajax_req = null;
                            return;
                        }
                        // goto url
                        if (json.url) {
                            document.location.href = json.url;
                            return;
                        }
                    }

                    if (json.modal) {
                        var modal = $(json.modal).first();
                        if (modal.find('.modal').length) {
                            var skelleton = $('.modal-skelleton').first();
                            if (skelleton.length) {
                                skelleton.find('.modal-content').html($modal);
                                skelleton.modal('show');
                            }
                        } else {
                            $('body').append(modal);
                            modal.modal();
                        }
                        if (json.question) {
                            modal.find('button.yes').click(function () {
                                if (json.redirect_if_yes) {
                                    document.location.href = json.redirect_if_yes;
                                } else {
                                    settings.data += '&_confirmed=1';

                                    // if there is a form in question, send the values from the form too
                                    var form = modal.find('form');
                                    if (form.length) {
                                        // settings.data += form.serialize();

                                        var form_on_success = form.data('onSuccess');
                                        if (form_on_success) {
                                            if (!('success' in settings) || !settings.success) {
                                                settings.success = form_on_success;
                                            } else {
                                                var x = settings.success;
                                                settings.success = function (a, b, c) {
                                                    x.call(a, b, c);
                                                    form_on_success(a, b, c)
                                                };
                                            }
                                        }

                                    }

                                    var params = $(this).parents('.modal').find('.modal-body form, .modal-body .form').find('input,select,textarea').serialize();
                                    if (params.length) {
                                        settings.data += '&' + params;
                                    }
                                    $.ajax(settings);
                                    modal.modal('hide');
                                }
                            }).focus();
                            if (json.redirect_if_no) {
                                modal.on('hide.bs.modal', function () {
                                    document.location.href = json.redirect_if_no;
                                });
                            }
                        }
                        //  if (modal.hasClass('signin-modal')) {
                        //      signinWrapper();
                        //  }
                    } else if (json.modalurl) {
                        if(!$("#loginModal").data('bs.modal')?.isShown) {
                            get_modal(json.modalurl);
                        }
                    }
                    if (json.field) {
                        var field;
                        if (json.field.match(/[^A-Za-z0-9_-]/)) {
                            field = $();
                            if (last_form) {
                                field = last_form.find(json.field + ':visible');
                            }
                            if (!field.length || !last_form) {
                                field = field.add($(json.field + ':visible'));
                            }

                        } else {
                            field = $();
                            if (last_form) {
                                field = last_form.find('[name="' + json.field + '"]:visible, #' + json.field + ':visible');
                            }
                            if (!field.length || !last_form) {
                                field = field.add($('[name="' + json.field + '"]:visible, #' + json.field + ':visible'));
                            }
                        }

                        if (field.length) {
                            // find most probable field. currently take the first
                            // find most probable: find open tab and look inside, find siblings tabs and look inside, go up...
                            field = field.first();

                            if (json.error && !json.no_form_error) {
                                field.parents('.form-group').first().addClass('has-error');
                            }

                            // show tabs if field is in hidden tab
                            field.parents('.tab-pane').each(function () {
                                $('[data-toggle=tab][href="#' + $(this).attr('id') + '"]').tab('show');
                            });

                            if (!field.attr('disabled') && !field.attr('readonly')) {
                                field.focus().select();

                                if (!field.closest('#right-menu').length) {
                                    $('html, body').animate({
                                        scrollTop: field.offset().top - 200
                                    });
                                }
                            }

                            $('.error-modal').on('hidden.bs.modal', function () {
                                field.focus();
                            });

                            if (json.error) {
                                field.showPopover(json.error, 3000);
                            }
                            //$('html, body').animate({ scrollTop: field.offset().top-100 }, 300);

                        } else {
                            if (json.error) {
                                alert(json.error);
                            }
                        }
                    }
                    // default logo
                    if (json.restaurant_logo) {
                        $('.restaurant-logo').attr('src', json.restaurant_logo);
                    }
                    if (json.restaurant_logo_url) {
                        $('.restaurant-logo-url').attr('href', json.restaurant_logo_url);
                    }
                }
            });
    })();

    // tos changed modal
    if ($('html[data-tos-info]').length) {
        $.get($('html[data-tos-info]').attr('data-tos-info'));

        // tos accept
        $('body').on('click', '#tos-changed-accept', function () {
            var a = $(this);

            // send req and close modal on success
            a.async(function () {
                a.closest('.modal').modal('hide');
            });
            return false;
        });

        // tos decline backdrop
        $('body').on('shown.bs.modal', '#tosChangedDecline', function () {
            $(this).data('bs.modal').$backdrop.css({
                //'opacity': 0,
                'z-index': 1099
            });
        });

        // tos decline
        $('body').on('click', '#tos-changed-decline', function () {
            var $modal = $($('#tosChangedDeclineTemplate').html());
            $modal.modal('show');

            return false;
        });
    }

    // open tab on start from hash
    if (window.location.hash && window.location.hash.match(/^#tab-[a-z0-9]+$/)) {
        $('[data-toggle=tab][href="' + window.location.hash + '"]').tab('show');
        // open tab on start from GET param
    } else if (document.location.search.substring(1).match(/btab=(.+)&?/)) {
        $('[data-toggle=tab][href="#' + RegExp.$1 + '"]').tab('show');
    }
    // toggle
    $('[data-toggle=main-tab][href]').on('click', function () {
        $($(this).attr('href')).tab('show')
    });

    // main (left) menu link is tab? add to history
    $('.main-menu .nav a[data-toggle=tab]').on('click fake-click', function () {
        var url = document.location.href;
        url = url.replace(/([\?&])tab=.+&?/, '$1');
        if (!url.match(/\?/)) {
            url += '?'
        }
        if (!url.match(/\?$/)) {
            url += '&';
        }
        var a = $(this), tab = a.attr('href').replace(/^#/, '');
        url += 'tab=' + tab;

        history.pushState({}, '', url);
    });
    // back key pressed and 
    $(window).on("popstate", function (e) {
        if (e.originalEvent.state !== null) {
            if (document.location.search.substring(1).match(/\btab=(.+)&?/)) {
                $('[data-toggle=tab][href="#' + RegExp.$1 + '"]').tab('show');
            }
        }
    });

    // bind event as first to override some default stuff
    $.fn.bindFirst = function (name, fn) {
        var elem, handlers, i, len;
        this.bind(name, fn);
        for (i = -1, len = this.length; ++i < len;) {
            elem = this[i];
            handlers = jQuery._data(elem).events[name.split('.')[0]];
            handlers.unshift(handlers.pop());
        }
    };

    // slideToggle
    $.fn.slideToggle3d = function (down_cb, up_cb) {
        var $this = $(this);

        if ($this.hasClass('height-transition')) {
            if (!$this.hasClass('height-transition-hidden')) {
                $this.slideUpTransition(up_cb || null);
            } else {
                $this.slideDownTransition(down_cb || null);
            }

        } else {
            if ($this.is(':visible')) {
                $this.slideUp(up_cb || null);
            } else {
                $this.slideDown(down_cb || null);
            }
        }

        return $this;
    };
    // find parent form (and a child in it)
    $.fn.form = function (child) {
        var p = $(this).parents('form').first();
        return child ? p.find(child) : p;
    };
    // shortcut for setting names
    $.fn.name = function (name) {
        return $(this).attr('name', name);
    };
    $.fn.showPopover = function (text, hide_timeout) {
        var $this = $(this).data('content', text);
        $this.popover({
            trigger: 'manual',
            placement: 'top',
            container: 'body'
        }).popover('show');
        if (hide_timeout) {
            $this.hidePopover(hide_timeout);
        }
        return $this;
    };
    $.fn.hidePopover = function (timeout) {
        var $this = $(this);
        setTimeout(function () {
            $this.popover('destroy');
        }, timeout || 0);
        return $this;
    };

    // focus buttons on modals
    $('body').on('shown.bs.modal', '.modal.question-modal', function () {
        var $this = $(this);
        if ($this.hasClass('.question-modal')) {
            $this.find('.yes').focus();
        } else if ($this.hasClass('.alert-modal')) {
            $this.find('.no').focus();
        } else {
            $this.find('input, button').focus();
        }
    });
    (function () {
        var question_confirm = function (e, data) {
            if (!data) {
                var that = $(e.target), event = e.type;
                var text = that.attr('data-confirm');
                var rs = that.attr('data-confirm-replace');
                if (rs) {
                    rs = rs.split('|');
                    text = text.replace(new RegExp(rs[0]), $(rs[1]).val());
                }

                modal_confirm(text, function () {
                    that.trigger(event, [true]);
                    $(this).modal('hide');
                    e.stopPropagation();
                    e.preventDefault();
                });
                e.stopImmediatePropagation();
                return false;
            }
        };
        $('[data-confirm]').addClass('data-confirmed').click(question_confirm);
        $('body').on('click submit', '[data-confirm]:not(.data-confirmed)', question_confirm);
    })();
    // checkme switch in dropdown menu
    // it adds .loading class and calls callbacks
    $('body').on('click', '.dropdown-menu .checkme', function (e) {
        var btn = $(this);
        $.ajax({
            url: btn.attr('href'),
            beforeSend: function () {
                btn.addClass('loading');
            },
            success: function () {
                btn.removeClass('loading').toggleClass('on');
                var cb_class = btn.data('cb-class') || btn.data('cb-on-class');

                if (cb_class) {
                    var cb_container = btn.data('cb-container');
                    if (cb_container) {
                        if (cb_container.match(/^(<)/)) {
                            cb_container = btn.parents(cb_container.replace(/^<\s*/, '')).first();
                        } else {
                            cb_container = $(cb_container)
                        }
                    } else {
                        cb_container = btn;
                    }
                    if (btn.hasClass('on')) {
                        cb_container.addClass(cb_class).removeClass(btn.data('cb-off-class'));
                        var on_text = btn.data('on-text');
                        if (on_text) {
                            btn.text(on_text);
                        }
                    } else {
                        cb_container.removeClass(cb_class).addClass(btn.data('cb-off-class'));
                        var off_text = btn.data('off-text');
                        if (off_text) {
                            btn.text(off_text);
                        }
                    }
                }
            },
            complete: function () {
                btn.removeClass('loading');
            }
        });
        e.stopPropagation();
        e.preventDefault();
        return false;
    });
    // modals
    $('body').on('click', 'a[data-toggle=modal][data-target]', function () {
        var a = $(this), modal = $(a.attr('data-target'));
        if (modal.length) {
            modal.modal('show');
            return false;
        }
        document.location.href = a.attr('href');
    }).find('.modal').addClass('keepit');

    // focus first input field on shown
    $('body').on('shown.bs.modal', '.modal', function () {
        var modal = $(this);
        modal.find('.modal-body input:visible').first().focus();
    });

    // larger modals
    $('body').on('show.bs.modal', '.modal', function () {
        var modal = $(this);
        modal.find('.modal-dialog').toggleClass('modal-l', modal.find('.modal-body.modal-l-body').length > 0);
        modal.find('.modal-dialog').toggleClass('modal-xxl', modal.find('.modal-body.modal-xxl-body').length > 0);
        modal.find('.modal-body input[type=text]').focus();
    });
    $('body').on('hidden.bs.modal', '.modal', function () {
        //$(this).removeData('bs.modal');
        $('.popover').hide();
        if (!$(this).hasClass('keepit')) {
            $(this).remove();
        }
    });
    $('body').on('click', '.modal-remote', function () {
        get_modal($(this).attr('href'));
        return false;
    });

    // modal forms
    $('body').on('submit', '.modal-body form:not([data-submit=false])', function () {
        var form = $(this), modal = $(this).parents('.modal'), error = modal.find('.error');

        var btns = modal.find('.btn-submit').button('loading');
        form.save(function (data) {
            btns.button('reset');

            if (data.error) {
                error.text(data.error).slideDown();
            }
            if (!form.parents('.modal-body.no-autoclose').length) {
                modal.modal('hide')
            }
            if (modal.attr('id') == 'loginModal') {
                modal.modal('hide')
            }
        });
        return false

    }).on('click', '.modal-footer .btn-submit', function () {
        $(".modal").scrollTop(0);
        $(this).parents('.modal-footer').siblings('.modal-body').find('form').submit();
    });

    // span[data-href] as link replacement
    $('body').on('click fake-click', '[data-href]:not(.no-auto-async)', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var $this = $(this);

        if ($this.hasClass('async')) {
            $this.async();
            return false;
        } else {
            if ($this.attr('data-target') == 'new') {
                window.open($this.attr('data-href'));
            } else {
                document.location.href = $this.attr('data-href');
            }
        }
    });

    // usually a swtich with data-status-url
    $('body').on('click', '[data-status-url]', function () {
        var $this = $(this)
        $.ajax({
            url: $this.attr('data-status-url'),
            success: function (data) {
            }
        })
    });

    // submit remote forms
    $('body').on('click', '[type=submit][data-form-target]', function () {
        var button = $(this);
        var form = $(button.attr('data-form-target'));
        if (form.length) {
            button.button('loading').attr('data-loading-reset', 1);
            form.submit();
        }

        return true;
    });

    // select with data-url
    $('body').on('change', 'select[data-url]', function () {
        var $this = $(this), val = $.trim($this.val());
        if (!val) {
            return;
        }
        document.location.href = $this.attr('data-url') + '?' + $this.attr('name') + '=' + encodeURIComponent(val);
    });

    // link toggle
    $('body').on('click', '[data-toggle=div][data-toggle-div]', function () {
        var $this = $(this), state,
            div = $($this.attr('data-toggle-div')),
            toggle_type = div.attr('data-toggle-type');
        if (toggle_type) {
            if (toggle_type == 'slide') {
                if (div.hasClass('height-transition')) {
                    if (div.hasClass('height-transition-hidden')) {
                        div.trigger('show.div.slide');
                        div.slideDownTransition();
                        state = true;
                        div.trigger('shown.div.slide');
                    } else {
                        div.trigger('hide.div.slide');
                        div.slideUpTransition();
                        state = false;
                        div.trigger('hidden.div.slide');
                    }
                } else {
                    if (div.is(':visible')) {
                        div.trigger('hide.div.slide');
                        div.slideUp(function () {
                            div.trigger('hidden.div.slide');
                        })
                    } else {
                        div.trigger('show.div.slide');
                        div.slideDown(function () {
                            div.trigger('shown.div.slide');
                        });
                    }
                    state = div.is(':visible');
                }
            } else {
                div.toggleClass(toggle_type);
                state = div.is(':visible');
            }
        } else {
            div.toggle();
            state = div.is(':visible');
        }
        $this.trigger('toggle-label');

        if (div.length && $this.attr('data-state-url') && $this.attr('data-state-name')) {
            $.ajax({
                url: $this.attr('data-state-url'),
                type: 'post',
                data: {
                    k: $this.attr('data-state-name'),
                    v: state
                }
            });
        }

    }).on('toggle-label', '[data-toggle-alt-label]', function () {
        var $this = $(this), alt_label = $this.attr('data-toggle-alt-label');
        if (alt_label) {
            $this.attr('data-toggle-alt-label', $this.text()).text(alt_label);
        }
    });

    // submit on change
    $('body').on('change', 'select.submit-on-change', function () {
        return $(this).closest('form').submit();
    });

    // load links async
    $.fn.async = function (success, complete) {
        var a = $(this);

        $.ajax({
            url: a.attr('href') || a.attr('data-href'),
            dataType: "json",
            beforeSend: function () {
                if (a.attr('data-loading-text')) {
                    a.button('loading');
                }
                if (a.prop("tagName").toUpperCase() == 'A' || a.hasClass('cursor-async-loading')) {
                    $('html').addClass('async-loading');
                }
            },
            complete: function (req, status) {
                var json = req.responseJSON || null;
                if (!json || !json.redirect) {
                    a.button('reset');
                }
            },
            success: success || function (data) {
                a.trigger('async-success', [data])
            }
        });
    };
    $('body').on('click', 'a.async', function (e) {
        var $this = $(this);

        if ($this.hasClass('no-propagation')) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
        }

        $this.async();
        return false;

    }).on('submit', 'form.async', function () {
        $(this).save();
        return false;
    });

    //prevent chnaging checkbox
    $('body').on('click', '[type=checkbox][readonly]', function (e) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        return false;
    });

    // nice checkbox
    $('body').on('change', '.nice-select [type=checkbox]:not([data-auto-update=false])', function () {
        var $this = $(this);
        $this.closest('.nice-select').toggleClass('selected', $this.is(':checked'));
    });
    $('body').on('change', '.nice-checkbox [type=checkbox]', function () {
        var $this = $(this);
        $this.closest('.nice-checkbox').toggleClass('selected', $this.is(':checked'));
    });
    // close icons in dismissible alerts
    $('body').on('click', '.alert.alert-dismissible .close.save-data[data-url]', function () {
        var $this = $(this);

        $.ajax({
            url: $this.attr('data-url'),
            type: 'post',
            data: {
                k: $this.attr('name'),
                v: $this.attr('data-value') || 1
            }
        });
    });

    // async submit form. just call (form.save())
    $.fn.save = function (success_cb, complete_cb) {
        var form = $(this);
        form.find('.has-error').removeClass('has-error');
        form.find('[data-content]').popover('destroy');
        last_form = form;

        $.ajax({
            url: form.attr('action'),
            data: form.serialize(),
            type: form.attr('method') || 'get',
            beforeSend: function () {
                form.find('[type=submit]').button('loading');
            },
            success: function (data) {
                if (success_cb) {
                    success_cb.call(form, data);
                }
                var toggle = form.attr('data-toggle-success');
                if (toggle) {
                    toggle = $(toggle);
                    if (toggle.length) {
                        form.fadeOut(function () {
                            toggle.removeClass('hidden').fadeIn();
                        });
                    }
                }
            },
            complete: function (data) {
                var json;
                if (data && 'responseJSON' in data) {
                    json = data.responseJSON;
                }
                if (!json ||
                    (!json.redirect && !json.url)
                ) {
                    form.find('[type=submit]').button('reset');
                    form.parents('.modal').first().find('.btn-submit').button('reset');
                }

                if (form.hasClass('mark-required')) {
                    form.trigger('mark-required');
                }
                if (complete_cb) {
                    complete_cb.call(form, data);
                }
            },
        })
    };

    // notifications
    $('#notification-area').on('click', '.close', function () {
        $(this).parents('.notification').trigger('disappear', [1])
    });
    // notification END 

    // dropdown menu selector
    var dropdown_menu_select = function (e) {
        var a = $(this);

        var val = $.trim(a.attr('data-value'));
        var txt = a.text();
        var menu = a.parents('.dropdown-menu-select');
        menu.find('.dropdown-toggle').focus();

        if (a.attr('data-no-update') == 'true') {
            e.stopImmediatePropagation();
            e.preventDefault();
            e.stopPropagation();

            menu.removeClass('open');
            a.trigger('selected');

        } else {
            var toggle = menu.find('.dropdown-toggle').attr('data-quantity-unit', val).attr('data-quantity-unit-txt', txt);
            a.hasClass('data-html-value') ? toggle.html(a.html()) : toggle.text(a.attr('data-show-value') || txt);
            if (a.hasClass('availability')) {
                toggle.html(toggle.html() + ' <img class="rightbar-block-title-arrow-positioning rightbar-block-title-arrow-up availability-dropdown-arrow" src="/img/rightbar-icons/arrow.svg">');
            } else if (a.hasClass('import-option')) {
                toggle.html('<p class="iod-button-text">' + toggle.html() + '</p> <i class="arrow-down"><img src="/img/app-icons/arrow.svg"></i> <i class="arrow-up hidden"><img src="/img/app-icons/arrow-white.svg"></i>');
            } else if (a.hasClass('navbar-sm-a')) {
                toggle.html(toggle.html() + ' <img src="/img/app-icons/arrow.svg">');
            } else if (a.hasClass('extra-option-selector-item')) {
                toggle.html(toggle.html() + ' <img style="transform: scale(0.75);" src="/img/app-icons/arrow.svg" alt="">');
            } else if (a.hasClass('budget-year-option')) {
                toggle.html(toggle.html() + ' <img src="/img/app-icons/arrow.svg">');
            } else {
                toggle.html(toggle.html() + ' <span class="caret"></span>');
                if (toggle.hasClass('gesoca-pseudobtn')) {
                    toggle.next('input[type="hidden"]').val(toggle.attr('data-quantity-unit'));
                }
            }

            var toggle_name = toggle.attr('data-dropdown-menu-input-name');
            if (toggle_name) {
                var b1 = toggle.siblings('input[type=hidden].' + toggle_name).val(val).attr('data-quantity-unit-txt', txt);
                if (b1.length && e.type != 'fake-click') {
                    b1.trigger('updated').trigger('change', [a]);
                }

                b1 = toggle.form('[name="' + toggle_name + '"]').val(val).attr('data-quantity-unit-txt', txt);
                if (b1.length && e.type != 'fake-click') {
                    b1.trigger('updated').trigger('change', [a]);
                }
            }


            a.closest('li').addClass('selected').siblings('.selected').removeClass('selected');
            if (!a.hasClass('dont-close-dropdown')) {
                menu.removeClass('open');
            }
            menu.trigger('changed', [a]);
        }

        return false;
    };
    $('body').on('click', '.dropdown-menu-select:not(.no-auto-select) .dropdown-toggle ~ .dropdown-menu li:not(.disabled) a', dropdown_menu_select);
    $('body').on('clicked fake-click', '.dropdown-menu-select .dropdown-toggle ~ .dropdown-menu li:not(.disabled) a', dropdown_menu_select);
    $('body').on('reset', '.dropdown-menu-select .dropdown-toggle[data-default-text]', function (e) {
        var toggle = $(this);
        toggle.html(toggle.attr('data-default-text') + ' <span class="caret"></span>');

        /*
        var toggle_name = toggle.attr('data-dropdown-menu-input-name');
        var val = toggle.attr('data-value-default');
        if (toggle_name) {
            var b1 = toggle.siblings('input[type=hidden].' + toggle_name).val(val);
            if (b1.length) {
                b1.trigger('updated').trigger('change');
            }

            b1 = toggle.form('[name="' + toggle_name + '"]').val(val);
            if (b1.length) {
                b1.trigger('updated').trigger('change');
            }
        }
        */
    });


    $('body').on('mouseover mouseout', '.xdropdown-menu-select', function (e) {
        var menu = $(this)
        if (e.type == 'mouseover') {
            menu.data('over', true);
            menu.addClass('open')
        } else {
            menu.removeData('over', true);
            setTimeout(function () {
                if (!menu.data('over')) {
                    menu.removeClass('open')
                }
            }, 200);
        }
    });

    $('body').on('click', '.btn-sm-close', function () {
        $(this).parents('.sm-menu').trigger('hide')
    });
    $('body').on('click', '.nav-tabs .disabled', function (e) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        return false;
    });


    // blink element
    $.fn.blinkMe = function () {
        var a = this.addClass('blink-background');
        setTimeout(function () {
            a.addClass('blink')
        }, 50);
        setTimeout(function () {
            a.removeClass('blink');
            setTimeout(
                function () {
                    a.removeClass('blink-background')
                }, 50)
        }, 500);
        return this;
    };

    // save setting state on change 
    var update_setting = function (e) {
        var $this = $(this),
            checkbox = $this.is(':checkbox'),
            radio = $this.is(':radio'),
            data,
            // wait if it is not checkbox
            timeout = checkbox ? 0 : 300;

        setTimeout(function () {
            if (checkbox) {
                data = {
                    k: $this.attr('name'),
                    v: $this.is(':checked') ? true : false
                };
            } else
                if ($this.is(':radio') || $this.attr('type') == 'hidden') {
                    data = {
                        k: $this.attr('name'),
                        v: $this.val()
                    };
                } else {
                    data = {
                        k: $this.attr('data-key'),
                        v: $this.attr('class')
                    };
                }

            $.ajax({
                url: $this.attr('data-url'),
                type: 'post',
                data: data,
                success: function (data) {
                    $this.trigger('status-updated', data)
                }
            });
        }, $this.attr('data-update-timeout') || timeout)

        if ($this.attr('data-pre-inform') == 'true') {
            $this.trigger('pre-status-updated')
        }
    };
    $('body').on('change', 'input[type=checkbox][data-url][data-update-settings=true]', update_setting);
    $('body').on('click', '[data-key][data-url][data-update-settings=true]', update_setting);


    // show popover help in bunch of nodes
    $('body').on('click', '.popover-help-wrapper .popover-help-toggle', function (e) {
        var wrapper = $(this).closest('.popover-help-wrapper'),
            visible = wrapper.toggleClass('popover-help-visible').hasClass('popover-help-visible');
        if (visible) {
            $('html, body').animate({
                scrollTop: wrapper.offset().top - 50
            }, .8, 'swing', function () {
                wrapper.find('[data-content]').popover({
                    container: 'body'
                }).popover('show').each(function () {
                    $(this).data('bs.popover')
                        .tip()
                        .addClass('popover-help');
                })
            });
        } else {
            wrapper.find('[data-content]').popover('destroy');
        }
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
    });
    $('body').on('click', '.popover-help-wrapper.popover-help-visible', function () {
        $('.popover-help-wrapper .popover-help-toggle').click();
    });
    // hide popover on click
    $('body').on('click', '.popover:not(.tour):not(.no-click-close)', function () {
        $(this).popover('destroy');
    });

    // keep auto-heighted textarea
    $('textarea.auto-height.keep-height-if-not-empty').on('blur', function () {
        var $this = $(this);
        $this.toggleClass('not-empty', ($.trim($this.val()).length > 0))
    });

    // general auto-complete. preload for all
    $.fn.initAutcompleteLoad = function () {
        $(this).filter('[data-url-autocomplete][data-autocomplete-autoload=true]').each(function () {
            var form = $(this);
            if (form.data('inited') == 1) {
                return;
            }

            form.data('inited', 1);
            var q = form.find('input[type=text]');
            var activeElement;
            var bh = new Bloodhound({
                datumTokenizer: function (d) {
                    return Bloodhound.tokenizers.whitespace(d.val)
                },
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                limit: 15,
                //prefetch: q.attr('data-url-ings-prefetch')
                remote: {
                    url: form.attr('data-url-autocomplete'),
                    replace: function (url, query) {
                        var params_send = form.attr('data-params-send'), params = '';
                        url += '/' + query + '?filter=recipes,products,groups';

                        if (params_send) {
                            url += '&' + $.param(form.find(params_send).serializeArray());
                        }

                        if (form.attr('data-search-cache') == 'false') {
                            url += '&_ttl=' + Date.now();
                        }

                        if (Object.keys(window.selectedFiltersTexts).length) {
                            url = url.replace('recipes,products,groups', window.selectedFiltersTexts[window.icounter]);
                        }
                        
                        return url;
                    },
                    ajax: {
                        beforeSend: function () {
                            var aE = $(document.activeElement);
                            if (!aE.length || aE.is('body')) {
                                return;
                            }
                            activeElement = aE.addClass('loading');
                            $('div.filter-buttons[data-icounter="' + window.icounter + '"]').addClass('move-filter-buttons');
                        },
                        complete: function (d) {
                            if (activeElement) {
                                activeElement.removeClass('loading');
                                $('div.filter-buttons[data-icounter="' + window.icounter + '"]').removeClass('move-filter-buttons');
                            }
                        }
                    },
                    // filter out stuff which are already on the list
                    filter: function (ings) {
                        var el = activeElement || $(document.activeElement);
                        el.removeClass('loading');
                        return ings;
                        /*
                                        var ids = $.map(el.parents('.recipes').first().find('.recipe-id'), function(input) {
                                            return $(input).val()*1;
                                        });

                                        return (ids.length == 0) ? ings : $.map(ings, function(ing) {
                                            if ($.inArray(ing.id*1, ids) == -1) {
                                                return ing;
                                            }
                                        });
                        */
                    }
                },
                sorter: function (a, b) {
                    if ('o' in a && 'o' in b) {
                        return a.o - b.o;
                    }
                }
            });
            bh.initialize();
            var suggestion = $(form.attr('data-autocomplete-suggestion'));
            var empty = $(form.attr('data-autocomplete-empty'));
            var footer = $(form.attr('data-autocomplete-footer'));
            var header = $(form.attr('data-autocomplete-header'));

            q.typeahead({
                minLength: 1,
                highlight: true,
                searchOnFocus: true,
            }, {
                displayKey: 'name',
                source: bh.ttAdapter(),
                templates: {
                    suggestion: suggestion.length ? Handlebars.compile(suggestion.html()) : null,
                    empty: empty.length ? Handlebars.compile(empty.html()) : null,
                    footer: footer.length ? Handlebars.compile(footer.html()) : null,
                    header: header.length ? Handlebars.compile(header.html()) : null,
                }
            }).on('typeahead:selected', function (jQ, o, s) {
                if ($(form).attr('data-typeahead-selected-callback') != 'true') {
                    if (o.ajax) {
                        $.get(o.url);
                    } else {
                        document.location.href = o.url;
                    }
                } else {
                    form.trigger('autocomplete-item-selected', [o]);
                    if ($(form).attr('data-autocomplete-ingredient-id')) {
                        var checked_recipes_number = 0;
                        $('.suggested-recipe-positioning').each(function () {
                            if ($(this).find('input').prop('checked')) { checked_recipes_number++; }
                        });

                        if (checked_recipes_number == 0) {
                            $('.ing-replace-save-btn').prop('disabled', true);
                        } else {
                            $('.ing-replace-save-btn').prop('disabled', false);
                        }
                        var ing_el = $(this).closest('input[name="ingredient_that_will_replace"]');
                        ing_el.attr('data-ingredient-id', o.id);
                        ing_el.attr('data-product-group-id', o.product_group_id);
                        ing_el.attr('data-recipe-id', o.ing_recipe_id);
                        if (o.own) {
                            ing_el.attr('data-ingredient-type', 'own');
                        } else {
                            ing_el.attr('data-ingredient-type', 'all');
                        }
                    }
                }
            }).on('focus', function () {
                //console.log(123)
                //q.typeahead('val', $.trim(q.typeahead('val'))+' '); 
            });
        });
    };
    $('[data-url-autocomplete][data-autocomplete-autoload=true]').initAutcompleteLoad();

    // cancel clicked? go back
    $('.btn-cancel, .btn-back, .xbtn-back').click(function () {
        window.history.back(-1);
        return false;
    });

    $("#backFromOrderReloadCart").click(function () {
        let shopId = $('#shopId').val();
        window.location.replace('/shop/' + shopId + '/cart');
    });

    // Left Arrow that should go back
    // back from order item to order list by shop ID
    $("#backFromOrderToOrderList").click(function () {
        let shopId = $('#shopId').val();
        window.location.replace('/shop/' + shopId + '/orders');
    });

    // select box: placeholder fix
    $('select.not-selected[required]').on('change', function (e) {
        $(this).removeClass('not-selected')
    }).on('onload', function (e) {
        var sel = $(this);
        var opt = sel.find('option').filter(':selected:last');
    }).trigger('onload');

    // tooltips
    $('[data-toggle=tooltip]').each(function () {
        var $this = $(this);
        $this.tooltip({
            trigger: $this.data('trigger') || 'hover',
            placement: $this.attr('data-placement') || 'top',
        })
    });

    // propagate focus to parent for css
    $('body').on('focusin focusout', '.twitter-typeahead input', function (e) {
        $(this).closest('.twitter-typeahead').toggleClass('focus', e.type == 'focusin')
    });

    // save alert status on close
    $('.alert[data-set-url]').on('close.bs.alert', function () {
        $.get($(this).attr('data-set-url'));
    });

    $('.hidden-radio').click(function () {
        var $this = $(this), input = $this.find('[type=radio]');
        $('input[name="' + input.attr('name') + '"]').closest('.hidden-radio').removeClass('active');
        $this.addClass('active');
        input.trigger('clicked');
    });

    // toggle element by using slide effect
    $('[data-toggle=slide]').click(function () {
        var $this = $(this), target = $($this.attr('href')),
            connwith = $this.attr('data-toggle-connect');
        if (connwith) {
            $this = $this.add(connwith);
        }

        if (target.is(':visible')) {
            target.slideUp(function () {
                $this.removeClass('active');
            })
        } else {
            target.slideDown(function () {
                $this.addClass('active');
            });
        }
        return false;
    });
    // toggle element by using fade effect
    $('[data-toggle=fade]').click(function () {
        var $this = $(this), target = $($this.attr('href')),
            connwith = $this.attr('data-toggle-connect');
        if (connwith) {
            $this = $this.add(connwith);
        }

        if (target.is(':visible')) {
            target.fadeOut(function () {
                $this.removeClass('active');
            })
        } else {
            target.fadeIn(function () {
                $this.addClass('active');
            });
        }
        return false;
    });

    // checkbox dependencies
    $('body').on('click fake-click change', 'input[type=checkbox][data-deps]', function (e) {
        var $this = $(this),
            deps = $this.attr('data-deps'),
            reverse = $this.attr('data-deps-reverse') == 'true',
            when_changed = $this.attr('data-deps-changed') == 'true';

        if (deps) {
            var checked = $this.is(':checked');
            deps = $(deps);

            if (deps.length) {
                if ((checked && !reverse) || (!checked && reverse)) {
                    deps.removeAttr('disabled').parents('.checkbox, .conf-parent, .form-group').removeClass('disabled');
                } else if ((checked && reverse) || (!checked && !reverse)) {
                    deps.attr('disabled', 'disabled').parents('.checkbox, .conf-parent, .form-group').addClass('disabled');
                }
            }
        }
    });
    $('[type=checkbox][data-deps]').trigger('fake-click');

    // show search
    (function () {
        var search_area = $('#search-area');
        if (search_area.length && $('.smartphone-menu:visible').length) {
            var search_btn = $('#show-search-area').removeClass('hide');
            search_btn.on('click', function () {
                if (!search_area.is(':visible')) {
                    search_area.closest('.main-content-menu').addClass('open');
                    search_area.slideDown(function () {
                        $(this).find('input[type=text]').focus();
                    });
                } else {
                    search_area.closest('.main-content-menu').removeClass('open');
                    search_area.slideUp();
                }
            });
        }
    }());

    // scroll
    $(window).on('scrollx', function (e) {
        let $elem = $('[data-class-if-scroll]')
        var _class = $elem.data('class-if-scroll')
        var scrollPos = $(document).scrollTop();
        if (scrollPos) {
            $elem.addClass(_class)
        } else {
            $elem.removeClass(_class)
        }
    });

    // sticky elements
    // toggle class on scroll
    $.fn.scrollClass = function () {
        var $this = $(this);
        $(this).each(function () {
            var doc = $(document),
                node = $(this),
                fixed = false,
                anchor = $(node.attr('data-scroll-anchor')),
                scroll_class = node.attr('data-class-if-scroll') || 'scrolled',
                offset = 0;

            // add additional offset if sticky menu is here and
            // event is not for called itself
            if (node.attr('id') != 'middle-content' && !node.hasClass('main-content-menu') && node.prop("tagName") != 'BODY') {
                offset = $('#top-navbar').outerHeight();

                var sticky_header = $('.main-content-menu:first');
                if (sticky_header.length) {
                    offset += sticky_header.outerHeight() - 45;
                }
            }

            if (!node.length) {
                return;
            }

            var onScroll = function (e) {
                var docTop = doc.scrollTop() + offset,  // add a few pixels because of menu
                    nodeTop = node.offset().top;

                if (docTop > nodeTop) {
                    if (!fixed) {
                        //console.log(1, node, offset, docTop, nodeTop);
                        node.addClass(scroll_class);
                        anchor.addClass(scroll_class);
                        fixed = true;
                    }
                } else {
                    if (fixed) {
                        //console.log(2, node, offset, docTop, nodeTop);
                        node.removeClass(scroll_class);
                        anchor.removeClass(scroll_class);
                        fixed = false;
                    }
                }
            };

            $(window).on('scroll', onScroll).trigger('scroll');
        })
        return $this;
    };

    $('[data-class-if-scroll], [data-scroll-track]').scrollClass();

    // sticky header fix:
    // this adds padding-top to elements below sticky header 
    (function () {
        var sticky_header = $('.main-content-menu:first');
        if (sticky_header.length) {
            var sticky_header_height = sticky_header.outerHeight();
            if (sticky_header_height > 85) {
                $('body').addClass('large-sticky-header');
                $('.main-content-menu ~ *, ~ .tab-content').css('padding-top', sticky_header_height);
            } else
                if (sticky_header.height() == 0) {
                    sticky_header.addClass('no-content');
                }
        }
    })();

    // tabs load
    $('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
        var tab_id = $(this).attr('href').replace(/^#/, '');
        var tab = $('.tab-pane[data-scroll-load-url][id="' + tab_id + '"]');

        if (tab.length) {
            $(window).triggerHandler('scroll', [true]);
        }
    });

    // GENERIC STUFF END
    ////////////////////
    // focus username input
    $('[name=_username]').focus();

    // costcenter switcher open/close
    (function () {
        var cc_switcher = $('#costcenter-switcher');

        if (cc_switcher.length) {
            var close_cc_switcher = function () {
                cc_switcher.removeClass('open');
            };

            cc_switcher.find('.current').click(function (e) {
                e.stopPropagation();
                // if open, add window event
                if (cc_switcher.toggleClass('open').hasClass('open')) {
                    $(window).on('click', close_cc_switcher);
                } else {
                    $(window).off('click', close_cc_switcher);
                }

            });
            cc_switcher.find('.no-ordering-info[data-url]').click(function (e) {
                e.stopPropagation();
                var $this = $(this), url = $this.attr('data-url');

                if ($this.attr('target') == 'new') {
                    window.open(url);
                } else {
                    document.location.href = url;
                }
                return false;
            });
            cc_switcher.find('a').click(function () {
                $(this).async(function (data) {
                    if (!data || !data.redirect) {
                        close_cc_switcher();
                    }
                });
                return false;
            });

            new PerfectScrollbar(cc_switcher.find('.choose').get(0), {
                wheelSpeed: .5
            });
        }
    })();

    // perfect scrollbar
    (function () {
        $(window).resize(function () {
            var height;
            // left menu
            var left_menu = $('#left-menu-items');
            if (left_menu.length) {
                height = $(window).height() - $('#top-navbar').outerHeight() - ($('.main-menu > .shop-logo').outerHeight() || 0) - ($('.main-menu #costcenter-switcher').outerHeight() || 0);
                left_menu.css({
                    position: 'relative',
                    height: (height - 30),
                    width: left_menu.outerWidth()
                }).addClass('scrollbar-visible');
                new PerfectScrollbar(left_menu.get(0), {
                    wheelSpeed: .5
                });
            }
            // cart menu
            var cart_menu = $('#right-menu .cart-short:not(.other-shop) .cart-short-products');
            if (cart_menu.length) {
                height = $(window).height() - $('#top-navbar').outerHeight() - ($('#right-menu .pricebox').outerHeight() || 0);
                cart_menu.css({
                    position: 'relative',
                    height: (height - 110),
                    width: cart_menu.outerWidth()
                }).addClass('scrollbar-visible');
                new PerfectScrollbar(cart_menu.get(0), {
                    wheelSpeed: .5
                });
            }
            // recipe-view menu 
            var recipe_view_right_menu = $('.recipe-view-page #right-menu');
            if (recipe_view_right_menu.length) {
                height = $(window).height() - $('#top-navbar').outerHeight();
                recipe_view_right_menu.css({
                    height: height,
                    width: recipe_view_right_menu.outerWidth()
                });
                new PerfectScrollbar(recipe_view_right_menu.get(0), {
                    wheelSpeed: .5
                });
            }
            // recipe-edit menu 
            var recipe_form_right_menu = $('.recipe-form-page #right-menu');
            if (recipe_form_right_menu.length) {
                height = $(window).height() - $('#top-navbar').outerHeight();
                recipe_form_right_menu.css({
                    height: height,
                    width: recipe_form_right_menu.outerWidth()
                });
                new PerfectScrollbar(recipe_form_right_menu.get(0), {
                    wheelSpeed: .5
                });
            }
            // unused recipest menu 
            var unused_recipes_right_menu = $('.edit-connection-page #right-menu #unused-recipes');
            if (unused_recipes_right_menu.length) {
                height = $(window).height() - $('#top-navbar').outerHeight();
                unused_recipes_right_menu.css({
                    height: height,
                    width: unused_recipes_right_menu.outerWidth()
                });
                new PerfectScrollbar(unused_recipes_right_menu.get(0), {
                    wheelSpeed: .5
                });
            }
            // recipes right menu filters
            // var restaurants_right_menu = $('#right-menu .restaurants .subwell-content');
            // if (restaurants_right_menu.length) {
            //     height = ($(window).height() - $('#top-navbar').outerHeight()) / 2 - 85;
            //     restaurants_right_menu.css({
            //         position: 'relative',
            //         maxHeight: height,
            //     }).addClass('scrollbar-visible');
            //     new PerfectScrollbar(restaurants_right_menu.get(0), {
            //         wheelSpeed: .5
            //     });
            // }
            // var categories_right_menu = $('.recipes-body #right-menu .categories .subwell-content');
            // if (categories_right_menu.length) {
            //     height = ($(window).height()-$('#top-navbar').outerHeight()) - ($('#right-menu .restaurants .subwell-content').outerHeight() || 0) - 
            //         ($('#right-menu .archived').outerHeight() || 0) - ($('#right-menu .recipe-type-filter').outerHeight() || 0) - 30;
            //     categories_right_menu.css({
            //         position: 'relative',
            //         maxHeight: height - 120,
            //         opacity: 1
            //     }).addClass('scrollbar-visible');
            //     new PerfectScrollbar(categories_right_menu.get(0), {
            //         wheelSpeed: .5,
            //         suppressScrollX: true
            //     });
            // }
            // contribution-margin right menu
            var cmcalc_right_menu = $('#right-menu #contribution-margin-well');
            if (cmcalc_right_menu.length) {
                height = $(window).height() - $('#top-navbar').outerHeight();
                cmcalc_right_menu.css({
                    height: height,
                    width: cmcalc_right_menu.outerWidth()
                });
                new PerfectScrollbar(cmcalc_right_menu.get(0), {
                    wheelSpeed: .5
                });
            }
            // contribution-margin categories
            var cmcalc_categories = $('body:not(.recipes-body) #right-menu #categories-well-filter');
            if (cmcalc_categories.length) {
                height = $(window).height() - $('#top-navbar').outerHeight() -
                    ($('#right-menu #contribution-margin-well .restaurants .subwell-content').outerHeight() || 0) - 140;
                cmcalc_categories.css({
                    position: 'relative',
                    maxHeight: height,
                    width: cmcalc_right_menu.outerWidth()
                });
                new PerfectScrollbar(cmcalc_categories.get(0), {
                    wheelSpeed: .5
                });
            }
            // contribution-margin restaurants
            var cmcalc_restaurants_well = $('#right-menu #contribution-margin-well .restaurants');
            if (cmcalc_restaurants_well.length) {
                height = ($(window).height() - $('#top-navbar').outerHeight()) / 2 - 85;
                restaurants_right_menu.css({
                    position: 'relative',
                    maxHeight: height,
                }).addClass('scrollbar-visible');
                new PerfectScrollbar(restaurants_right_menu.get(0), {
                    wheelSpeed: .5
                });
            }
        }).trigger('resize');
    })();

    // design mode switch
    (function () {
        $('body').on('click change', '[name=design-mode]', update_setting);
        $('body').on('pre-status-updated', '[name=design-mode]', function () {
            var html = $('html'), mode = $(this).val();

            if (mode == 'toggle') {
                html.toggleClass('light');
            } else
                if (mode == 'light') {
                    html.addClass('light');
                } else
                    if (mode == 'dark') {
                        html.removeClass('light');
                    }
        });
        $('body').on('discover', '[name=design-mode]', function () {
            var light = $('html').hasClass('light'),
                $this = $(this), label = $this.closest('label'),
                val = $this.val();

            if ((light && $this.val() == 'light') || (!light && val == 'dark')) {
                label.button('toggle');
            }
        });
        $('body').on('click change', '#popover-design-mode-switch', function (e) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();

            var $this = $(this);
            var html = $('html').toggleClass('light');

            $.ajax({
                url: $this.attr('data-url'),
                type: 'post',
                data: {
                    k: $this.attr('data-key'),
                    v: html.hasClass('light') ? 'light' : 'dark'
                },
                success: function () {
                    //$('.popover').popover('hide')
                }
            });
            $('[name=design-mode]').trigger('discover');

            return false;
        });
    })();


    // new stuff notifications
    (function () {
        var url = $('html').attr('data-notifications-url');

        if (url) {
            $.get(url);
        }
    })();

    $('#full-page-preloader[data-load-url]').each(function () {
        var $this = $(this);
        $.get($this.attr('data-load-url'));
    });

    $('.ing-row-space-organisation, .ing-table-row').hover(function () {
        $(this).find('.ing-options-width').removeClass('hidden');
    }, function () {
        $(this).find('.ing-options-width').addClass('hidden');
    });

    ////////////////////////
    // end of document ready
});

// is loading modal open
function loadingModalOpen() {
    return $('#loading-modal').length > 0;
};
window.loadingModalOpen = loadingModalOpen;

// globaly accessible variable for function
var google_map_init;
window.google_map_init = google_map_init;


// notifications
$.fn.notify = function (x) {
    notification(x)
};

function notification(msg, hide_other_notifications) {
    if (typeof msg == 'string') {
        msg = { msg: msg }
    }
    ;
    if (msg.html) {
        msg.msg = new Handlebars.SafeString(msg.msg)
    }
    var tmpl = $('#notification-tmpl');
    if (!tmpl.length) {
        return
    }
    var noti = $(Handlebars.compile(tmpl.html())(msg));
    var hide_notification = function (n) {
        n.height(n.height());
        n.addClass('disappear');
        setTimeout(function () {
            n.addClass('remove');
            setTimeout(function () {
                n.remove();
            }, parseInt(n.css('transition-duration')) * 1000 || 300);
        }, parseInt(n.css('transition-duration')) * 1000 || 300);
    };

    var area = $('#notification-area');
    if (hide_other_notifications) {
        area.find('.notification').each(function () {
            hide_notification($(this))
        });
    }

    area.append(noti);
    noti.on('disappear', function (e, now) {
        setTimeout(function () {
            hide_notification(noti)
        }, now ? 0 : (msg.timeout * 1000 || 3000));
    });
    if (!msg.static) {
        noti.trigger('disappear');
    }
}

window.notification = notification;

// show error funcs
function show_err(err) {
    var modal = $('.modal-content:visible:has(.error)');
    if (modal.length) {
        modal.find('.error').text(err).slideDown();
        ;
    } else {
        alert(err);
    }
}

window.show_err = show_err;

function ajax_err(data) {
    if (data && data.error) {
        show_err(data.error);
        if (data.field) {
            $('#' + field + ',[name=' + field + ']').first().focus();
        }
        return true;
    }
    if (!data || !data.status || data.status != 1) {
        return true;
    }
}

window.ajax_err = ajax_err;

// get modal shortcut
function get_modal(url, cb) {
    $.getJSON(url, { modal: true }, cb || function () {
    });
    return;

    var modal = $('.modal-skelleton');
    if (!modal.length) {
        $.getJSON(url + '?modal=true');
    } else {
        modal.modal({ remote: url });
    }
}

window.get_modal = get_modal;

// confirm (question) thru modals
// this should be on top before other checks
function modal_confirm(msg, cb_yes, cb_no, input, input_text) {
    var modal, form;
    if (input) {
        modal = $('.input-modal');
        modal.find('.input-title').text(msg);
        if (input_text) {
            modal.find('.input-text').val(input_text);
        }
        form = modal.find('form').on('submit', function () {
            modal.find('.yes').click();
            return false;
        });

    } else {
        modal = $('.question-modal');
        var m = msg.match(/^(.*)\|(.*)$/);
        if (m) {
            modal.find('.question-title').text(m[1]);
            msg = m[2];
        }
        modal.find('.question').text(msg);
    }

    if (cb_yes) {
        var yes = modal.find('.yes');
        yes.on('click', function () {
            if (input) {
                cb_yes.apply(modal, [modal.find('.input-text').val()]);
            } else {
                cb_yes.apply(modal);
            }
            yes.off('click');
        });
    }
    if (cb_no) {
        var no = modal.find('.no');
        no.on('click', function () {
            cb_no.apply(modal);
            no.off('click');
        });
    }

    modal.on('hidden.bs.modal', function () {
        var $this = $(this);
        $this.find('.yes, .no').off('click');
        $this.find('form').off('submit');
    });

    modal.modal('show');
    return modal;
};
window.modal_confirm = modal_confirm;

// modal input
function modal_input(msg, cb_yes, cb_no, text) {
    return modal_confirm(msg, cb_yes, cb_no, true, text);
};
window.modal_input = modal_input;

// alert thru modals
function modal_alert(msg, cb_no) {
    var modal = $('.alert-modal');
    var m = msg.match(/^(.*)\|(.*)$/);
    if (m) {
        modal.find('.alert-title').text(m[1]);
        modal.find('.modal-header').show();
        msg = m[2];
        //  } else {
        //      modal.find('.modal-header').hide();
    }
    modal.find('.alert').text(msg);

    if (cb_no) {
        modal.find('.no').click(cb_no);
    }

    modal.on('shown.bs.modal', function () {
        $(this).find('.no').focus();
    });
    modal.modal('show');
};
window.modal_alert = modal_alert;

// http://stackoverflow.com/questions/7125453/modifying-css-class-property-values-on-the-fly-with-javascript-jquery
// http://jsfiddle.net/hdU8s/11/
// set css style
function setStyle(cssText) {
    var sheet = document.createElement('style'), isIE = false;
    sheet.type = 'text/css';
    window.customSheet = sheet;
    (document.head || document.getElementsByTagName('head')[0]).appendChild(sheet);
    try {
        sheet.cloneNode(false).appendChild(document.createTextNode(''));
    }
    catch (err) {
        isIE = true;
    }
    var wrapper = isIE ? document.createElement('div') : sheet;
    return (setStyle = function (cssText, node) {
        if (!node || node.parentNode !== wrapper)
            node = wrapper.appendChild(document.createTextNode(cssText));
        else node.nodeValue = cssText;
        if (isIE) sheet.styleSheet.cssText = wrapper.innerHTML;
        return node;
    })(cssText);
}

window.setStyle = setStyle;

function removeStyle(name) {
    setStyle('', name);
}

window.removeStyle = removeStyle;

// translate api
var text_translate = function (from, to, text, cb) {
    $.ajax({
        url: '/translate',
        type: 'post',
        data: {
            source: from,
            target: to,
            q: text
        },
        success: cb
    });
};
window.text_translate = text_translate;

// lazy load for body
function init_scroll_load(force_load) {
    $('[data-scroll-load-url]').each(function () {
        var scroll_loaded;
        var scroll_loading;
        var scroll_wrapper = $(this), url = $.trim(scroll_wrapper.attr('data-scroll-load-url'));
        var wait_for_visibility = (scroll_wrapper.attr('data-scroll-load-visibility-check') == 'true');

        if (url.length) {
            var win = $(window), doc = $(document),
                scrollDown = function () {
                    return (win.scrollTop() + win.height()) > (doc.height() - 200);
                }, simulate_scroll = scrollDown();

            if (force_load) {
                simulate_scroll = true;
            }

            if (!scroll_wrapper.data('scroll-event-added')) {
                scroll_wrapper.data('scroll-event-added', true);

                scroll_wrapper.on('reload-scroll', function () {
                    var old_url = scroll_wrapper.attr('data-scroll-load-url-old');

                    if (old_url) {
                        scroll_wrapper
                            .removeClass('scroll-loaded')
                            .attr('data-scroll-load-url', old_url)
                            .html('');

                        win.triggerHandler('scroll');
                        console.log('reload')
                    }
                });

                win.scroll(function (e, force_scroll) {
                    if (scroll_wrapper.hasClass('scroll-loaded') || scroll_wrapper.hasClass('scroll-loading')) {
                        return
                    }

                    if (force_scroll || simulate_scroll || scrollDown()) {
                        var load_url = function () {
                            var load = function () {
                                $.ajax({
                                    url: scroll_wrapper.attr('data-scroll-load-url'),
                                    beforeSend: function () {
                                        scroll_loading = true;
                                        scroll_wrapper.addClass('scroll-loading');
                                    },
                                    success: function (data) {
                                        if (data.html) {
                                            scroll_wrapper.append(data.html).trigger('data-scrolled-out');
                                        } else {
                                            scroll_loaded = true;
                                            scroll_wrapper.addClass('scroll-loaded');
                                        }
                                        if (data.url || data.scroll_load_url) {
                                            scroll_wrapper.attr('data-scroll-load-url', data.url || data.scroll_load_url);
                                            scroll_loaded = false;
                                        } else {
                                            scroll_loaded = true;
                                            scroll_wrapper.addClass('scroll-loaded');
                                            scroll_wrapper.attr('data-scroll-load-url-old', scroll_wrapper.attr('data-scroll-load-url'));
                                            scroll_wrapper.removeAttr('data-scroll-load-url');
                                        }

                                        scroll_loading = false;
                                        simulate_scroll = false;
                                        scroll_wrapper.removeClass('scroll-loading');

                                        // continue loading under some circumstances 
                                        if (!scroll_loaded && (scrollDown() || scroll_wrapper.attr('data-full-load') == 'true')) {
                                            scroll_wrapper.trigger('loaded.part');
                                            setTimeout(function () {
                                                load_url();
                                            }, scroll_wrapper.attr('data-scroll-load-wait') || 0);
                                        } else {
                                            scroll_wrapper.removeClass('scroll-loading');
                                            scroll_wrapper.trigger('loaded.full');
                                        }
                                    },
                                    complete: function (data, a) {
                                        scroll_wrapper.removeClass('loading');
                                        scroll_wrapper.removeClass('scroll-loading');
                                    },
                                    error: function (data, a) {
                                        scroll_loading = false;
                                    }
                                });
                            };

                            // check whether the node is visible... if not wait and check every 200ms 
                            if (wait_for_visibility && !scroll_wrapper.is(':visible')) {
                                clearInterval(scroll_wrapper.data('wait_for_visibility_interval'));

                                scroll_wrapper.data('wait_for_visibility_interval', setInterval(function () {
                                    if (scroll_wrapper.is(':visible')) {
                                        clearInterval(scroll_wrapper.data('wait_for_visibility_interval'));
                                        load();
                                    }
                                }, 200));
                            } else {
                                load();
                            }


                        };

                        load_url();
                    }
                });
            }

            if (simulate_scroll) {
                win.triggerHandler('scroll');
            }
        } else if (scroll_wrapper.length && !url.length) {
            scroll_wrapper.addClass('scroll-loaded');
        }

    });
}

window.init_scroll_load = init_scroll_load;

// helpers
function inArray(s, a) {
    return $.inArray(s, a) >= 0
}

window.inArray = inArray;


function localizedNumber(n) {
    return (n + '').replace(/\./, ',')
}

window.localizedNumber = localizedNumber;

function toNumber(s) {
    return s ? (s + '').replace(/,/, '.') * 1 : null
}

window.toNumber = toNumber;

function isNumber(s, decimal) {
    return s && ((decimal && s.match(/^\d+([\.,]\d+)?$/)) || (!decimal && s.match(/^\d+$/)))
}

window.isNumber = isNumber;

function toPrice(s, d, fd) {
    if (d == null) {
        d = 2;
    }
    var n = localizedNumber(roundToDecimals(s || 0, d));
    while (!fd && n.match(/[\.,](.*0)$/) && RegExp.$1.length > 2) {
        n = n.replace(/0$/, '')
    }
    ;
    return n;
}

window.toPrice = toPrice;

function roundToDecimals(num, decimals) {
    if (decimals == null) {
        decimals = 2;
    }
    if (!$.isNumeric(num)) {
        num = 0
    }
    ; var sign = num >= 0 ? 1 : -1;

    return (Math.round((num * Math.pow(10, decimals)) + (sign * 0.001)) / Math.pow(10, decimals)).toFixed(decimals)
}

window.roundToDecimals = roundToDecimals;

String.prototype.capitalizeFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

export function htmlLoading(state) {
    $('html').toggleClass('async-loading', state === null ? true : state);
};

// slide transitions: http://weblog.west-wind.com/posts/2014/Feb/22/Using-CSS-Transitions-to-SlideUp-and-SlideDown
$.fn.slideUpTransition = function (cb) {
    return this.each(function () {
        var $this = $(this), h = $this.css("max-height"), t = 0;
        if (!h || h == 'none') {
            $this.css("max-height", $this.outerHeight());
            t = 1
        }
        ; setTimeout(function () {
            $this.css("max-heightx", "0").addClass("height-transition-hidden");
            if (cb) {
                setTimeout(function () {
                    cb.call()
                }, 520)
            }
        }, t)
    })
};
$.fn.slideDownTransition = function (cb) {
    return this.each(function () {
        var $this = $(this).removeClass("height-transition-hidden").css("max-height", "none"),
            height = $this.outerHeight();
        $this.css("max-height", "0");
        setTimeout(function () {
            $this.css({ "max-height": height });
            if (cb) {
                setTimeout(function () {
                    cb.call()
                }, 520)
            }
        }, 1)
    })
};

function nl2br(str) {
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br />' + '$2')
};
window.nl2br = nl2br;

function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};
window.guid = guid;
function ref_popover(els) {
    return $(els).each(function () {
        var $el = $(this);

        var conf = {
            html: true,
            delay: { show: 0, hide: 100 },
            content: function () {
                var ref = $(this).data("element-ref")
                var content = $(ref).html();
                $('.tooltip').tooltip('hide')
                return content;
            }
        };

        if ($el.attr('data-trigger-hide')) {
            conf.trigger = 'manual';

            var hideSubmenu = function () {
                clearTimeout($el.data('hideTimeout'));
                $el.data('hideTimeout', setTimeout(function () {
                    if (!$el.siblings(".popover").filter(':hover').length) {
                        $el.popover('hide');
                    }
                }, 500));
            };
            $el
                .on($el.attr('data-trigger'), function () {
                    $el.popover('show');
                    $el.siblings(".popover").on("mouseleave", hideSubmenu);
                })
                .on($el.attr('data-trigger-hide'), hideSubmenu);
        }

        $el.popover(conf);
    });
}

window.ref_popover = ref_popover;

function rand_id(prefix) {
    return (prefix || '') + Math.random().toString().replace(/[^\d]/, '').substr(0, 8);
}

window.rand_id = rand_id;


/**
 * Handle keyboard input with delaying action
 * @param {Event} event 
 * @param {Object} timeoutObj //define object with { static timeout = null; } in global scope and pass class name
 * @param {Function} action 
 * @param {Object} element //passing parameter for action
 * @returns {Boolean|}
 */
function handleKeyboardInputDelay(event, timeoutObj, action, element, delay = 300) {
    var keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
        event.preventDefault();
        return false;
    }
    if (timeoutObj.timeout !== null) { clearTimeout(timeoutObj.timeout); }
    timeoutObj.timeout = setTimeout(() => { action(element) }, delay);
}

window.handleKeyboardInputDelay = handleKeyboardInputDelay;


$(document).ready(function () {

    $(document).on('click', function (event) {
        if (!$(event.target).closest('.navbar-sm-module-selector').length && !$(event.target).closest('.navbar-sm-button').length) {
            $('.navbar-sm-parent-div').removeClass('background-effect');
            $('.navbar-sm-button').find('img').css('transform', 'rotate(0deg)');
        }
    });

    $('.navbar-sm-button, .navbar-sm-li').on('click', function () {
        if ($('.navbar-sm-dropdown').hasClass('open')) {
            $('.navbar-sm-parent-div').removeClass('background-effect');
            $('.navbar-sm-button').find('img').css('transform', 'rotate(0deg)');
        } else {
            $('.navbar-sm-parent-div').addClass('background-effect');
            $('.navbar-sm-button').find('img').css('transform', 'rotate(180deg)');
        }
    });
});
